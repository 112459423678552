import React, { Suspense, useMemo } from 'react'
import { CircularProgress } from '@mui/material'

type MfData = {
  [key: string]: unknown
}

const MicroFrontendLoader = ({
  functionType,
  mfData,
}: {
  functionType: string
  mfData: MfData
}) => {
  const MicroFrontend = useMemo(
    () => React.lazy(() => System.import(functionType)),
    [functionType]
  )

  return (
    <Suspense fallback={<CircularProgress />}>
      <MicroFrontend {...mfData} />
    </Suspense>
  )
}

export default MicroFrontendLoader
