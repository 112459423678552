import React from 'react'
import { createRoot, Root } from 'react-dom/client'
import { Router } from 'react-router-dom'
import { History } from 'history'
import MicroFrontendLoader from './components/MicroFrontendLoader'

type MfData = {
  [key: string]: unknown
}

type SystemProxyArgs = {
  target: string
  history: History
}

const systemProxyReactAppFactory = ({ target, history }: SystemProxyArgs) => {
  let root: Root | null = null
  const targetElement = document.getElementById(target)
  if (targetElement) {
    root = createRoot(targetElement)
  }
  return {
    render: (functionType: string, mfData: MfData) => {
      root?.render(
        <Router history={history}>
          <MicroFrontendLoader functionType={functionType} mfData={mfData} />
        </Router>
      )
    },
    unmount: () => {
      root?.unmount()
      root = null
    },
  }
}

export default systemProxyReactAppFactory
